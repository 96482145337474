// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCumdlFwxUYWAVQ_VHAIStG3Udq7Zca64c",
  authDomain: "yvomnew1st.firebaseapp.com",
  databaseURL: "https://yvomnew1st-default-rtdb.firebaseio.com",
  projectId: "yvomnew1st",
  storageBucket: "yvomnew1st.appspot.com",
  messagingSenderId: "401170148940",
  appId: "1:401170148940:web:bb416e8846fd04b7774ee9",
  measurementId: "G-EYVCESC2GD"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };